.content {
  margin: auto;
  max-width: 1280px;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.lines {
  position: relative;
  top: 0px;
  height: 5px;
  color: transparent;
}

.lines.open {
  transition: 500ms;
  transition-delay: 850ms;
  color: inherit;
  height: 5px;
}

@keyframes closeleft {
  0% {
    background-color: white;
  }
  99% {
    background-color: white;
  }
  100% {
    background-color: inherit;
  }
}

@keyframes closeright {
  0% {
    background-color: white;
  }
  99% {
    background-color: white;
  }
  100% {
    background-color: inherit;
  }
}

@keyframes openleft {
  0% {
    background-color: white;
  }
  100% {
    background-color: white;
  }
}

@keyframes openright {
  0% {
    background-color: white;
  }
  100% {
    background-color: white;
  }
}

.bookcontainer {
  position: relative;
}

.flexcol {
  button {
    width: min-content;
    color: inherit;
    justify-content: center;
    height: 50px;
  }
  padding-bottom: 100px; // might remove later
}

.bluebook {
  display: flex;
  background-color: rgb(35, 159, 216);
  border-color: rgb(35, 159, 216);
}
.redbook {
  display: flex;
  background-color: red;
  border-color: red;
}

.left {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-102%, 0%);
  transition: 850ms;
}

.left.open {
  padding-right: 20px;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-102%, 0%);
  transition: 850ms;
}

.right {
  position: absolute;
  top: -25%;
  left: 42.5%;
  transform: translate(2%, 0%);
  transition: 850ms;
}

.right.open {
  padding-left: 20px;
  position: absolute;
  top: -25%;
  left: 42.5%;
  transform: translate(2%, 0%);
  transition: 850ms;
}

.bookleft {
  transition: 850ms;
  transition-delay: 0ms;
  width: 0px;
  height: 100px;
  border-color: inherit;
  border-style: solid;
  border-width: 3px;
  animation-name: closeleft;
  animation-duration: 850ms;
  background-color: inherit;
}

.bookleft.open {
  transition: 850ms;
  transition-delay: 650ms;
  width: 80px;
  height: 100px;
  color: inherit;
  border-width: 3px;
  //   border-left-width: 80px;
  border-color: inherit;
  //   border-radius: 10px;
  border-style: solid;
  border-left-width: 5px;
  border-right-width: 2px;
  animation-name: openleft;
  animation-duration: 850ms;
  animation-delay: 850ms;
  background-color: white;
}

.bookright {
  transition: 850ms;
  transition-delay: 650ms;
  width: 0px;
  height: 100px;
  border-width: 3px;
  border-left-width: 80px;
  border-color: inherit;
  //   border-radius: 10px;
  border-style: solid;
  animation-name: closeright;
  animation-duration: 850ms;
  animation-delay: 850ms;
  background-color: inherit;
}

.bookright.open {
  transition: 850ms;
  transition-delay: 0ms;
  width: 80px;
  height: 100px;
  color: inherit;
  border-width: 3px;
  border-left-width: 2px;
  border-right-width: 5px;
  border-color: inherit;
  border-style: solid;
  animation-name: openright;
  animation-duration: 850ms;
  background-color: white;
}


// mui
.MuiCard-root.same-height {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}