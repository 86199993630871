@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* textarea default */
.textAreaAutoSize {
  font-family: "Dancing Script", cursive;
}

/* all fonts for the notebooks here */


@font-face {
  font-family: "AllCaps";
  src: local("AllCaps"), url("fonts/allcaps/AllCaps.ttf") format("truetype");
  font-weight: bold;
}

.font-face-allcaps {
  font-family: "AllCaps" !important;
}

@font-face {
  font-family: "helium";
  src: local("helium"), url("fonts/helium/helium.ttf") format("truetype");
  font-weight: bold;
}

.font-face-helium {
  font-family: "helium" !important;
}

@font-face {
  font-family: "Radith";
  src: local("Radith"), url("fonts/radith/Radith.ttf") format("truetype");
  font-weight: bold;
}

.font-face-radith {
  font-family: "Radith" !important;
}

@font-face {
  font-family: "SemiSweet";
  src: local("SemiSweet"),
    url("fonts/semi_sweet/SemiSweet.ttf") format("truetype");
  font-weight: bold;
}

.font-face-semisweet {
  font-family: "SemiSweet" !important;
}

@font-face {
  font-family: "CedarvilleCursive-Regular";
  src: local("CedarvilleCursive-Regular"),
    url("fonts/CedarvilleCursive-Regular.ttf") format("truetype");
  font-weight: bold;
}

.font-face-cedarvillecursive {
  font-family: "CedarvilleCursive-Regular" !important;
}

@font-face {
  font-family: "Notebook-Regular";
  src: local("Notebook-Regular"),
    url("fonts/notebook/Notebook-Regular.ttf") format("truetype");
  font-weight: bold;
}

.font-face-notebook {
  font-family: "Notebook-Regular" !important;
}

@font-face {
  font-family: "Handwritten";
  src: local("Handwritten"),
    url("fonts/Handwritten.otf") format("truetype");
  font-weight: bold;
}

.font-face-handwritten {
  font-family: "Handwritten" !important;
}

@font-face {
  font-family: "Minimalist";
  src: local("Minimalist"),
    url("fonts/Minimalist.otf") format("truetype");
  font-weight: bold;
}

.font-face-minimalist {
  font-family: "Minimalist" !important;
}


@font-face {
  font-family: "Simple-Alphabet";
  src: local("Simple-Alphabet"),
    url("fonts/Simple-Alphabet.otf") format("truetype");
  font-weight: bold;
}

.font-face-simplealphabet {
  font-family: "Simple-Alphabet" !important;
}

@font-face {
  font-family: "Modern-Designer";
  src: local("Modern-Designer"),
    url("fonts/Modern-Designer.otf") format("truetype");
  font-weight: bold;
}

.font-face-moderndesigner {
  font-family: "Modern-Designer" !important;
}


@font-face {
  font-family: "Modern-Handmade";
  src: local("Modern-Handmade"),
    url("fonts/Modern-Handmade.otf") format("truetype");
  font-weight: bold;
}

.font-face-modernhandmade {
  font-family: "Modern-Handmade" !important;
}

@font-face {
  font-family: "Modernsans";
  src: local("Modernsans"),
    url("fonts/Modernsans.ttf") format("truetype");
  font-weight: bold;
}

.font-face-modernsans {
  font-family: "Modernsans" !important;
}


@font-face {
  font-family: "CoolKids";
  src: local("CoolKids"),
    url("fonts/CoolKids.ttf") format("truetype");
  font-weight: bold;
}

.font-face-coolkids {
  font-family: "CoolKids" !important;
}

@font-face {
  font-family: "Daisy-Violette";
  src: local("Daisy-Violette"),
    url("fonts/Daisy-Violette.ttf") format("truetype");
  font-weight: bold;
}

.font-face-daisyviolette {
  font-family: "Daisy-Violette" !important;
}

@font-face {
  font-family: "Caramel";
  src: local("Caramel"),
    url("fonts/Caramel.ttf") format("truetype");
  font-weight: bold;
}

.font-face-caramel {
  font-family: "Caramel" !important;
}

/* Caramel */

@font-face {
  font-family: "Caramel";
  src: local("Caramel"),
    url("fonts/Caramel.ttf") format("truetype");
  font-weight: bold;
}

.font-face-caramel {
  font-family: "Caramel" !important;
}

/* Cupcakes */

@font-face {
  font-family: "Cupcakes";
  src: local("Cupcakes"),
    url("fonts/Cupcakes.otf") format("truetype");
  font-weight: bold;
}

.font-face-cupcakes {
  font-family: "Cupcakes" !important;
}

/* BigBaby */

@font-face {
  font-family: "BigBaby";
  src: local("BigBaby"),
    url("fonts/Big-Baby.otf") format("truetype");
  font-weight: bold;
}

.font-face-bigbaby {
  font-family: "BigBaby" !important;
}

/* Calligraphy */

@font-face {
  font-family: "Calligraphy";
  src: local("Calligraphy"),
    url("fonts/Calligraphy.ttf") format("truetype");
  font-weight: bold;
}

.font-face-calligraphy {
  font-family: "Calligraphy" !important;
}