@import "./styles/Home";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

$journal-scale: 1.5;

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

iframe {
  display: none;
}

.textAreaBackgroundTop {
  // cloned
  font-size: 1rem * $journal-scale;
  line-height: 1.618rem * $journal-scale;

  background: linear-gradient(to bottom,
      #fff,
      #fff 1.2rem * $journal-scale,
      rgb(0, 102, 128) 1.3rem * $journal-scale,
      #fff 1.4rem * $journal-scale,
      #fff 1.618rem * $journal-scale );
  background-size: 100% 1.618rem * $journal-scale;
  width: 400px;
  color: black;
  background-clip: "content-box";
  width: 100%;
}


.textAreaBackground {
  // cloned
  font-size: 1rem * $journal-scale;
  line-height: 1.618rem * $journal-scale;

  background: linear-gradient(to bottom,
      #fff,
      #fff 1.2rem * $journal-scale,
      rgb(0, 102, 128) 1.3rem * $journal-scale,
      #fff 1.4rem * $journal-scale,
      #fff 1.618rem * $journal-scale );
  background-size: 100% 1.618rem * $journal-scale;
  width: 400px;
  color: black;
  background-clip: "content-box";
  width: 100%;
}

.textAreaAutoSize {
  font-family: "Dancing Script", cursive;
  font-size: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  background: inherit;
  width: 80%;
  overflow: hidden;
  resize: none;

  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-color: pink;
  border-left-color: red;
  font-size: 1rem * $journal-scale;
  line-height: 1.618rem * $journal-scale;
  background-size: 100% 1.618rem * $journal-scale;
  color: black;
  background: inherit;
}

:root {
  font-size: 16px;
  font-family: "Open Sans";
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #060b26;
}

.topnav {
  background-color: var(--bg-secondary);
  height: 5vh;
  display: flex;
  align-items: center;
}

.topnav-links {
  width: 100%;
  justify-content: space-between;
  flex: 1;

  span {
    display: table-cell;
    text-align: center;
  }

  a {
    margin-left: 16px;
  }

  button {
    margin-right: 16px;
  }
}


.navbar {
  z-index: 1;
  transition: 850ms ease;
  height: 95vh; //topnav is 5vh
  position: fixed;
  background-color: var(--bg-secondary);
  left: -150% ;
}

.navbar.active {
  left: 0;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 100%;
}

.nav-item {
  width: 100%;
}

.nav-item:last-child {
  margin-top: auto;
}

.nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  padding: 0 16px;
  text-decoration: none;
}

.link-text {
  display: none;
  margin-left: 1rem;
}

.nav-link svg {
  width: 2rem;
  margin: 0 1.5rem;
}

.nav-header {
  color: var(--text-secondary);

  a {
    color: var(--text-secondary);
    text-decoration: none;
  }
}

.nav-icon {
  color: var(--text-secondary);
}

.nav-item:hover {
  background-color: #000;
}

// login stuff

.login {
  button {
    color: var(--text-secondary);
  }
}

.accept {
  background-color: rgb(49 225 217 / 87%) !important
}
